<template>
	<!-- 首页主体内容 -->
	<div class="main bas">
		<!-- 轮播 -->
		<div class="iphone">
			<!-- <img src="@/assets/iphone.png" alt="" /> -->
			<div class="swiper-container" id="swiperIndex">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(item,i) in imgs" :key="i">
						<img :src="item.pic" alt="">
					</div>
				</div>
				<!-- 	<div class="swiper-pagination"></div>
				<div class="swiper-button-next"></div>
				<div class="swiper-button-prev"></div> -->
				<!-- <div class="swiper-scrollbar"></div> -->
			</div>
		</div>
		<div class="right">
			<div class="top">
				<img src="@/assets/logo.png" alt="" />
				<div class="desc">看见更美的世界</div>
			</div>
			<div class="qrcode">
				<div class="appbox ios">
					<div><img src="@/assets/ios.png" alt="" />iOS版下载</div>
					<div>
						<img src="@/assets/dowios.png" alt="" />
					</div>
				</div>
				<div class="appbox android">
					<div><img src="@/assets/ad.png" alt="" />安卓版下载</div>
					<div>
						<img src="@/assets/dowad.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import 'swiper/css/swiper.css'
	export default {
		data() {
			return {
				imgs: [{
						pic: require('@/assets/img/phone_01.png')
					},
					{
						pic: require('@/assets/img/phone_02.png')
					},
					{
						pic: require('@/assets/img/phone_03.png')
					},
					{
						pic: require('@/assets/img/phone_04.png')
					},
					{
						pic: require('@/assets/img/phone_05.png')
					}
				]
			}
		},
		mounted() {
			var mySwiper = new Swiper('#swiperIndex', {
				//配置分页器内容
				loop: true, // 循环模式选项
				pagination: {
					el: ".swiper-pagination", //换页器与哪个标签关联
					clickable: true //分页器是否可以点击
				},
				autoplay: {
					delay: 5000, //3秒切换一次
					pauseOnMouseEnter: true, //悬停停止切换
				},
			})
		}
	}
</script>

<style lang="less" scoped>
	.main {
		margin-top: 94px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;

		.iphone {
			width: 360px;
			height: 634px;
			margin-right: 100px;
			.swiper-wrapper {
				.swiper-slide {
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.right {
			margin-left: 40px;

			.top {

				>img {
					width: 222px;
					height: 76px;
					margin-bottom: 20px;
				}

				.desc {
					letter-spacing: 14px;
					font-size: 24px;
					color: rgba(184, 184, 184, 1);
				}
			}

			.qrcode {
				margin-top: 48px;
				display: flex;

				.appbox {
					width: 180px;
					height: 180px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					padding: 12px 0 15px;
					border-radius: 24px;
					background: #fff;
					text-align: center;

					>div {
						&:first-child {
							display: flex;
							align-items: center;
							justify-content: center;
							margin-bottom: 20px;

							>img {
								width: 20px;
								height: 24px;
								margin-right: 20px;
							}
						}

						&:last-child {
							width: 120px;
							height: 120px;

							>img {
								width: 100%;
								height: 100%;
							}
						}
					}
				}

				.ios {
					margin-right: 60px;
				}

				.android {}
			}
		}
	}
</style>