<template>
<!-- 业务板块第二屏内容 -->
  <div class="apply">
    <div class="bas">
      <div class="title">
        <h1>硬件应用</h1>
        <!-- 手机、平板电脑、电脑、电视机、投影仪、AR眼镜、VR眼镜 -->
      </div>
      <ul class="hardware">
        <li v-for="(item, index) in hardware" :key="index">
          <div>{{ item.name }}</div>
          <img :src="item.img" alt="" />
        </li>
      </ul>
      <div class="title">
        <h1>应用人群及场景</h1>
        <!-- 艺术家、艺术院校师生、艺术爱好者、艺术馆、博物馆、书画院、画廊、学校等等 -->
      </div>
      <ul class="crowd">
        <li v-for="(item, index) in crowd" :key="index">{{ item }}</li>
      </ul>
    </div>
    <!-- <stereoing-vue :top="50" :at="1"></stereoing-vue> -->
  </div>
</template>

<script>
import steerpointVue from "./steerpoint.vue";
export default {
  components: {
    steerpointVue,
  },
  data() {
    return {
      hardware: [
        { name: "手机", img: require("../assets/phone.png") },
        { name: "平板电脑", img: require("../assets/tabletPC.png") },
        { name: "电脑", img: require("../assets/computer.png") },
        { name: "电视机", img: require("../assets/TV.png") },
        { name: "投影仪", img: require("../assets/projector.png") },
        { name: "AR眼镜", img: require("../assets/AR.png") },
        { name: "VR眼镜", img: require("../assets/VR.png") },
      ],
      crowd: [
        "艺术家",
        "艺术院校师生",
        "艺术爱好者",
        "艺术馆",
        "博物馆",
        "书画院",
        "画廊",
        "学校",
        "更多人群",
      ],
    };
  },
};
</script>

<style lang="less" scoped>

  .apply {
  width: 100%;
  height: 100vh;
  background: rgba(72, 88, 135, 1);
  color: #fff;
  padding-top: 120px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  .title {
    font-size: 14px;
    margin-bottom: 50px;
    h1 {
      font-size: 24px;
      margin-right: 20px;
      display: inline-block;
    }
  }
  .hardware {
    display: flex;
    margin-bottom: 80px;
    > li {
      width: 160px;
      height: 200px;
      text-align: center;
      margin-right: 14px;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      > div {
        margin-top: 32px;
        position: relative;
        z-index: 9;
        font-size: 16px;
      }
      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 160px;
      }
    }
  }
  .crowd {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 14px;
    > li {
      width: 390px;
      height: 90px;
      text-align: center;
      line-height: 90px;
      background: #4e5f91;
    }
  }
}

</style>
