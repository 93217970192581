<template>
  <div id="app">
    <div
      class="one"
      :style="
        actvi ? 'background: linear-gradient(180deg, #485887 0%, #091847 100%);' : ''
      "
    >
      <div class="bas nav">
        <div class="logo"><img src="./assets/logo.png" alt="" /></div>
        <ul class="tab">
          <li
            v-for="(item, index) in tabs"
            :key="index"
            @click="acvt(index)"
            :class="{ action: index === actvi }"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <dow-app-vue v-show="actvi === 0"></dow-app-vue>
      <plate-vue v-show="actvi === 1"></plate-vue>
    </div>
    <apply-vue v-show="actvi === 1"></apply-vue>
    <footer class="bas bot">
      <span class="text"
        ><a href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2022012729号</a> |
        经营许可证编号：闽B2-20230229 | 云上兰亭（福建）文化艺术有限公司 |
        地址：福建省福州市鼓楼区温泉街道五四路111号宜发大厦2702 | 电话：400-097-8980</span
      >
    </footer>
  </div>
</template>

<script>
import applyVue from "./components/apply.vue";
import dowAppVue from "./components/dowApp.vue";
import plateVue from "./components/plate.vue";
import DevicePixelRatio from "./utils/devicePixelRatio";
export default {
  name: "App",
  components: {
    dowAppVue,
    plateVue,
    applyVue,
  },
  created() {
    //    判断后的操作
        if (this.isMobile()) {
          window.location.replace('http://www.yslt.art/m')
            // 判断true跳转到这个主页
        } else {
            // 判断false跳转到这个主页
        }
    console.log(window.devicePixelRatio);
    // new DevicePixelRatio().init();
  },
  data() {
    return {
      tabs: ["首页", "业务板块"],
      actvi: 0,
    };
  },
  methods: {
    acvt(i) {
      this.actvi = i;
    },
    //定义一个函数判断是手机端还是pc端
        isMobile() {
            if (
                window.navigator.userAgent.match(
                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                )
            ) {
                console.log('移动端')
                return true // 移动端
            } else {
                console.log('PC端')
                return false // PC端
            }
        },
  },
};
</script>

<style lang="less">
@import url("@/css/base.less");

  #app {
  width: 100vw;
  position: relative;
  font-family: PingFang SC-Medium, PingFang SC;
  h1 {
    letter-spacing: 2px;
  }
  .one {
    width: 100%;
    height: 100vh;
    background: url("./assets/backdrop.png") no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    .logo {
      width: 116px;
      height: 40px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .tab {
      display: flex;
      > li {
        font-size: 16px;
        color: rgba(184, 184, 184, 1);
        margin-right: 30px;
        cursor: pointer !important;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .action {
      color: #fff !important;
      border-bottom: #fff 2px solid;
    }
  }
  .bot {
    font-size: 14px;
    color: rgba(184, 184, 184, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    .text {
      display: inline-block;
      a {
        color: rgba(184, 184, 184, 1);
      }
    }
  }
}

</style>
