import { render, staticRenderFns } from "./steerpoint.vue?vue&type=template&id=c5a0d7d8&scoped=true&"
import script from "./steerpoint.vue?vue&type=script&lang=js&"
export * from "./steerpoint.vue?vue&type=script&lang=js&"
import style0 from "./steerpoint.vue?vue&type=style&index=0&id=c5a0d7d8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.1_css-loader@6.7.3_vue-template-compiler@2.7.14_webpack@5.76.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5a0d7d8",
  null
  
)

export default component.exports