<template>
<!-- tab栏 -->
  <ul class="bas" :style="{ 'margin-top': top + 'px' }">
    <li v-for="(item, index) in actvi" :key="index" :class="{ acrvi: at === index }"></li>
  </ul>
</template>

<script>
export default {
  props: {
    top: {
      type: String,
      default: "0",
    },
    at: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      actvi: 3,
    };
  },
};
</script>

<style lang="less" scoped>
ul {
  display: flex;
  align-items: center;
  li {
    width: 8px;
    height: 8px;
    margin-right: 20px;
    background: #fff;
    border-radius: 50%;
  }
  .acrvi {
    background: none;
    border: #fff solid 2px;
    margin-right: 16px;
  }
}
</style>
