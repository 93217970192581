<template>
<!-- 业务板块第一屏内容 -->
  <div class="plate bas">
    <h1>云上兰亭十大板块</h1>
    <span>布局文化艺术生态圈与产业链，云上艺术馆是版图的开始。</span>
    <h1>产品技术</h1>
    <span
      >沿用web2.0平面技术【APP、小程序、网页】 +
      当下将来web3.0空间技术【U3Dwebgl游戏引擎、AR、VR】</span>
    <div class="tenplate">
      <img src="../assets/plate.png" alt="" />
    </div>
    <div class="art">
      <img src="../assets/art.png" alt="" />
    </div>
    <!-- <steerpoint-vue :top="80" :at="0"></steerpoint-vue> -->
  </div>
</template>

<script>
import steerpointVue from "./steerpoint.vue";
export default {
  components: {
    steerpointVue,
  },
};
</script>

<style lang="less" scoped>

  .plate {
  height: 100vh;
  color: #fff;
  padding-top: 92px;
  position: relative;
  box-sizing: border-box;
  h1 {
    font-size: 28px;
    margin-bottom: 40px;
  }
  span {
    width: 469px;
    font-size: 14px;
    display: inline-block;
    margin-bottom: 80px;
    &:last-child {
      margin-bottom: 200px
    }
  }
  .art {
    width: 400px;
    height: 200px;
    z-index: 9;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .tenplate {
    position: absolute;
    left: 395px;
    top: 19px;
    width: 960px;
    height: 818px;
    z-index: 1;
    > img {
      width: 100%;
      height: 100%;
    }
  }
}

</style>
